import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import { Skeleton } from "@mui/material";


export default function CertificateGallery() {


    const [certificateData, setCertificateData] = useState([]);
    const [apiData, setApiData] = useState(true);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
      try{
        const response = await fetch(`${adminUrl}/api/certificate`);
        const data = await response.json();
      
        setCertificateData(data);
      }catch(error){
        console.error('error fetching data', error);
        setApiData(false);
      }finally{
        setLoader(false);
      }
    }


    useEffect(() => {
      fetchData();
    },[]);



    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 2, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };


    const certificate = [
        {
            id: 1,
            src : "certificate-1.jpg",
        },
        {
            id: 2,
            src : "certificate-3.jpg",
        },
        {
            id: 3,
            src : "certificate-8.jpg",
        },
        {
            id: 4,
            src : "certificate-10.jpg",
        }
    ];


    return (
        <>
          <h2 className="text-center section-title my-5">Certificates</h2>
        {
            loader ? 
                <Row>
                    <Col lg="3">
                        <Skeleton width="100%" height={500} style={{backgroundColor: 'grey'}}/>
                    </Col>
                    <Col lg="3">
                        <Skeleton width="100%" height={500} style={{backgroundColor: 'grey'}}/>
                    </Col>
                    <Col lg="3">
                        <Skeleton width="100%" height={500} style={{backgroundColor: 'grey'}}/>
                    </Col>
                    <Col lg="3">
                        <Skeleton width="100%" height={500} style={{backgroundColor: 'grey'}}/>
                    </Col>
                </Row>
                
            :
            <ReactOwlCarousel className="owl-theme" margin={20} responsive={responsiveOptions} autoplayHoverPause={true} nav={true} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                {
                    apiData && certificateData ? (
                        certificateData.map((certificate) => (
                            <div className="certificate-image">
                                <NavLink to={`${adminUrl}/uploads/certificate/${certificate.certificate_image}`} target='_blank'>
                                    <img src={`${adminUrl}/uploads/certificate/${certificate.certificate_image}`} className="img-thumbnail certificate" alt="certificate"/>
                                </NavLink>
                            </div>
                            ))
                            
                        ) : 
                        (
                            <Row>
                                {certificate.map((certificate, index) => (
                                    <Col key={`certificateId-${certificate.id}`} sm="12" md="3" lg="3" className="my-5">
                                        <div className="certificate-image">
                                            <img src={`assets/image/certificate/${certificate.src}`} className="img-thumbnail" alt="certificate"/>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        )
                }
            </ReactOwlCarousel>
        }
        </>
    )
}