import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const Section = styled.section`
    padding: 3em 0;

    .title{
        text-align: center;
        color:  var(--main-color);
    }

    .policy{
        font-size: 18px;
        padding: 1.5em 0;
    }
    .policy-content{
        margin-top: 1em;
        padding: .5em;
        background-color: rgb(243,243,243);

        p, ol, ul{
            margin:5px 0;
            font-size:15px; 
        }
        p strong{
            font-size:17px;
        }

        ol{
            list-style: numeric;
            margin-left: 20px;
            margin-top: 1em;
        }
        ul{
            list-style: circle;
            margin-left: 20px;
        }
        .sub-list{
            list-style: square;
        }
    }
`;

export default function Policy()
{

    return (
        <React.Fragment>
            <Helmet>
                <title>WSP - India | Privacy Policy</title>
                <meta title="privacy policy"/>
                <meta name="description" content="WSP provides a full suite of private contract security and patrol services with a customer-focused culture that is unique to the industry. We are able to tailor our offerings to clients’ needs due in-part to our well-trained personnel with varied backgrounds that include law enforcement, private security, customer service, and military experience."/>
            </Helmet>

            <Section>
                <Container>
                    <h2 className="title">Points for Privacy Policy</h2>

                    <div className="policy">
                        <strong>
                            <p>You have to give description about your company, what kind of work you do and services you provides?</p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                <strong>Western Safety Patrol OPC Private Limited</strong>
                            </p>
                            <p>
                                Western Safety Patrol OPC Private Limited is a dedicated security and safety solution provider. Our mission is to ensure the well-being of our clients, their personnel, and their valuable assets. Here are the key aspects of our services:
                            </p>

                            <ol>
                                <li>
                                    <strong>Security Patrols:</strong>
                                    <ul>
                                        <li>Our trained teams conduct thorough security patrols in designated areas. Whether it’s a factory, commercial establishment, or any other location, we proactively monitor and safeguard against potential threats.</li>
                                        <li>Using bikes for patrols ensures agility and quick response times.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>QR Code Integration:</strong>
                                    <ul>
                                        <li>We’ve implemented an innovative system using QR codes. Each code is mapped to a specific factory location.</li>
                                        <li>When our patrol officers scan these QR codes during their rounds, clients receive real-time updates. This transparency assures clients that their premises are actively monitored.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Visual Documentation:</strong>
                                    <ul>
                                        <li>Our patrol officers generate videos or photos during their rounds, capturing the situation at each location.</li>
                                        <li>Factory owners, plant heads, and supervisors gain peace of mind knowing that they have visual evidence of our diligent work.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Emergency Response Vehicles:</strong>
                                    <ul>
                                        <li>In emergencies or SOS situations, we swiftly deploy emergency response vehicles staffed with trained personnel.</li>
                                        <li>Our rapid assistance can make a significant difference during critical moments.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Fire Equipment Records:</strong>
                                    <ul>
                                        <li>We maintain meticulous records of fire equipment within factories.</li>
                                        <li>Regular checks ensure that fire extinguishers, alarms, and other safety tools are functional and ready for use.</li>
                                    </ul>
                                </li>

                            </ol>
                            <p>Our commitment to client satisfaction, transparency, and accountability sets us apart. Western Safety Patrol OPC Private Limited strives to create a safer environment for all.</p>
                        </div>
                    </div>

                    <div className="policy">
                        <strong>
                            <p>How user can access your application? </p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                To access our comprehensive security application, clients can follow these steps:
                            </p>

                            <ol>
                                <li>
                                    <strong>Contact Us:</strong>
                                    <ul>
                                        <li>Clients should reach out to us via our <strong>website</strong> or <strong>mobile platform</strong>.</li>
                                        <li>During this interaction, we will create their personalized accounts.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Account Creation:</strong>
                                    <ul>
                                        <li>Our team will assist clients in setting up their accounts.</li>
                                        <li>This step ensures that clients have secure access to the application.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Features:</strong>
                                    <ul>
                                        <li>Once their accounts are created, clients can:
                                            <ul className="sub-list">
                                                <li><strong>View Patrolling Reports: </strong>Access real-time patrolling reports, including video or photo documentation.</li>
                                                <li><strong>Generate SOS Requests: </strong>In case of emergencies, clients can swiftly generate SOS requests through the application.</li>
                                                <li>
                                                    <strong>Document Upload: </strong>We provide a dedicated space for clients to upload essential documents, such as medical records, insurance papers, or other critical information. <br />
                                                    <strong>Access Limited to Patrol Officers: </strong>Only our patrol officers can access these uploaded documents. <br />
                                                    <strong>Emergency Use: </strong>This features is available exclusively during SOS situations.
                                                </li>
                                            </ul>
                                        </li>
                                        
                                    </ul>
                                </li>
                            </ol>
                            <p>By initiating contact with us, clients gain peace of mind, knowing that their safety and security needs are in capable hands.</p>
                        </div>
                    </div>

                    <div className="policy">
                        <strong>
                            <p>Why you need camera permission? </p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                <strong>Camera Permission </strong>is essential for ensuring privacy and security when using mobile application. After granting camera access to app, it essentially allow to utilize mobile device’s camera for various purposes:
                            </p>

                            <ol>
                                <li>
                                    <strong>Taking Photos:</strong> Apps with camera permission can capture photos directly using device’s camera hardware. This feature is required for:
                                    <ul>
                                        <li>Document Scanning</li>
                                        <li>QR code scanning</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Recording Videos:</strong> Apps can record videos using the camera. This functionality is crucial for applications such as:
                                    <ul>
                                        <li>Factory unit video recording.</li>
                                        <li>Incient live recording</li>
                                        <li>Patrol Report</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Scanning QR Codes:</strong> Use the camera to scan QR codes. 
                                    <ul>
                                        <li>Each Unit has their unique QR</li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                    </div>

                    <div className="policy">
                        <strong>
                            <p>Why you need audio record permission?</p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                In our security application, audio recording permission plays a crucial role in enhancing the effectiveness of video recording. Here’s why it is necessary:
                            </p>

                            <ol>
                                <li>
                                    <strong>Live Situation Reporting:</strong>
                                    <ul>
                                        <li>When our patrolling officers visit client sites, they need to provide real-time updates on the situation.</li>
                                        <li>Audio serves as an efficient way to communicate concise messages. It allows officers to describe incidents, observations, and emergencies succinctly.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Enhanced Communication:</strong>
                                    <ul>
                                        <li>In critical scenarios, audio complements video. It provides context, emotion, and clarity.</li>
                                        <li>Our officers can articulate live status updates, ensuring that clients, emergency responders, or relevant parties receive accurate information promptly.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Incident Documentation:</strong> 
                                    <ul>
                                        <li>If an incident occurs on the road or within a facility, our patrolling officers can record it using both video and audio.</li>
                                        <li>This dual documentation ensures a comprehensive account of events, which can be invaluable for investigations, legal purposes, or insurance claims.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Clear Messages:</strong>
                                    <ul>
                                        <li>Audio recordings make messages clear and concise. In urgent situations, brevity matters.</li>
                                        <li>Whether it’s reporting a security breach, fire, or medical emergency, audio ensures that critical details are effectively conveyed.</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>By granting audio recording permission, users enable our officers to create comprehensive video logs with clear, contextualized information. This synergy between audio and video enhances safety and security across all client sites.</p>
                        </div>
                    </div>

                    <div className="policy">
                        <strong>
                            <p>Why you need permission for location and also why you need to access it in background also?</p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                Our security application leverages location services in various ways to enhance safety and efficiency. Let’s explore these functionalities:
                            </p>

                            <ol>
                                <li>
                                    <strong>SOS Location Identification:</strong>
                                    <ul>
                                        <li>When an SOS alert is triggered, our app precisely pinpoints the actual location from where the distress call originated.</li>
                                        <li>This accuracy ensures that our emergency response team can swiftly reach the SOS location, providing timely assistance.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Employee Live Tracking:</strong>
                                    <ul>
                                        <li>Our app continuously tracks the live location of employees, especially patrolling officers.</li>
                                        <li>By monitoring their positions, we can assess proximity to critical areas, emergency sites, or incidents.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Company Location Mapping:</strong> 
                                    <ul>
                                        <li>Each company’s location is meticulously mapped within our app.</li>
                                        <li>This mapping ensures that our patrolling officers can quickly locate and navigate to specific factories, offices, or industrial sites.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Missed Area Detection:</strong>
                                    <ul>
                                        <li>Our app tracks the patrol team’s movements.</li>
                                        <li>If any area is inadvertently missed during patrols, the app alerts us. We can then trace the missed location and ensure comprehensive coverage.</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>By integrating location services, Western Safety Patrol OPC Private Limited optimizes safety, response times, and overall security management.</p>
                        </div>
                    </div>

                    <div className="policy">
                        <strong>
                            <p>Why you need file access? (e.g. for document upload and profile image upload)</p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                In Western Safety Patrol OPC Private Limited’s application, granting file access permission serves essential purposes related to document management and communication. Let’s explore these functionalities:
                            </p>

                            <ol>
                                <li>
                                    <strong>Document Upload:</strong>
                                    <ul>
                                        <li>Our app allows users to upload essential documents, such as medical records, insurance papers, or other critical information.</li>
                                        <li>These documents are securely stored and accessible only to authorized personnel (such as patrol officers) when needed.</li>
                                        <li>Users can upload files related to their safety, compliance, or emergency preparedness.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Profile Picture:</strong>
                                    <ul>
                                        <li>Users have the option to set a profile picture within the app.</li>
                                        <li>This visual representation enhances user identification and personalization.</li>
                                        <li>Profile pictures are stored securely and associated with individual accounts.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Government Notices and Awareness Programs:</strong> 
                                    <ul>
                                        <li>Our app facilitates communication between users and relevant authorities.</li>
                                        <li>By accessing government notices, users stay informed about regulations, safety guidelines, and emergency protocols.</li>
                                        <li>Awareness programs, whether in text, image, or video format, are disseminated through the app for educational purposes.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Privacy and Security Considerations:</strong>
                                    <ul>
                                        <li>We respect user privacy and ensure that uploaded files remain confidential.</li>
                                        <li>File access permissions are granted explicitly by users during account setup.</li>
                                        <li>Our commitment is to use this access responsibly and solely for the intended purposes.</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>By granting file access permission, users contribute to a safer and more informed community.</p>
                        </div>
                    </div>

                    <div className="policy">
                        <strong>
                            <p>Why do you need device unique ID?</p>
                        </strong>
                        <div className="policy-content">
                            <p>
                                At Western Safety Patrol OPC Private Limited, we prioritize the confidentiality and security of our clients’ data. The use of a <strong>device unique ID</strong> serves as an additional layer of protection. Let’s delve into the reasons behind this requirement:
                            </p>

                            <ol>
                                <li>
                                    <strong>Mitigating Unauthorized Access:</strong>
                                    <ul>
                                        <li><strong>User Authentication: </strong>While usernames and passwords provide initial access, a device unique ID acts as a secondary authentication factor.</li>
                                        <li><strong>Reducing Vulnerabilities: </strong>Even if someone gains unauthorized access to a client’s credentials, they cannot misuse the application without the corresponding device ID.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Preventing Data Tampering:</strong>
                                    <ul>
                                        <li><strong>Client Information Integrity: </strong>Our application stores crucial and confidential data related to clients, their properties, and emergency protocols.</li>
                                        <li><strong>Device ID Verification: </strong>By cross-referencing the device ID, we ensure that any changes made to client information originate from authorized devices.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Emergency Situations and SOS Alerts:</strong> 
                                    <ul>
                                        <li><strong>Patrolling Officer Guidance: </strong> In SOS scenarios, accurate location data is critical. The device ID ensures that patrolling officers receive real-time, unaltered information.</li>
                                        <li><strong>Document Access Control: </strong>Only authorized devices can access crucial documents. This prevents unauthorized retrieval of sensitive information during emergencies.</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Accountability and Traceability:</strong> 
                                    <ul>
                                        <li><strong>Audit Trail: </strong>The device ID creates an audit trail. We can trace actions back to specific devices, enhancing accountability.</li>
                                        <li><strong>Forensic Analysis: </strong>In case of incidents or investigations, the device ID aids forensic analysis.</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>By implementing device unique IDs, we reinforce security, prevent data tampering, and maintain the trust of our clients. Their safety remains our utmost priority.</p>
                        </div>
                    </div>
                </Container>
            </Section>
        </React.Fragment>
    )
}