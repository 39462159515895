import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import { Skeleton } from "@mui/material";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Aos from "aos";
import 'aos/dist/aos.css';

export default function ServiceCarousel(){

    
    const serviceArray = [
        {
            id: 1,
            src: "rectangle-25.png",
            title: "Transportation",
            content: "We provide secure transportation for executives, diplomats, VIPs, healthcare patients, facility guests, and others.",
        },
        {
            id: 2,
            src: "rectangle-26.png",
            title: "vehicle patrol",
            content: "Mobile patrol is an effective and often lower-cost way to secure one or many sites.",
        },
        {
            id: 3,
            src: "rectangle-27.png",
            title: "alarm & incident response",
            content: "Personnel response solutions for alarms, incidents, and emergencies (Fire, Medical etc)",
        },
        {
            id: 4,
            src: "event-security1.jpg",
            title: "event security",
            content: "Our event security expertise includes trade shows, Fortune 500 Companies, medium-sized businesses, and local events.",
        },
        {
            id: 5,
            src: "cctv-camera.jpg",
            title: "security camera",
            content: "We offer cutting-edge security camera services to protect your property and ensure peace of mind.",
        },
        {
            id: 6,
            src: "security-consultant.jpg",
            title: "security consultancy",
            content: "We are experts in the field of developing preventative and emergency procedures to ensure organization-wide continuity of operations."
        },
        {
            id: 7,
            src: "fire-safety.jpg",
            title: "fire system services",
            content: "We ensure fire safety with expert services, protecting lives and property from potential hazards."
        }
    ];
    

    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 2, // Show 2 cards at 500px and up
        },
        1000: {
            items: 3, // Show 3 cards at 1000px and up
        },
    };
    
    const [serviceData, setServiceData] = useState([]);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';
    
    const fetchData = async () => {
        try{
            const[serviceResponse] = await Promise.all([
                fetch(`${adminUrl}/api/service`).then((response) => response.json()) 
            ]);
            setServiceData(serviceResponse);
        }catch(error){
            console.error('Error fetching data:', error);
            
        }finally{
            setLoader(false);
        }
    }
    
  
    useEffect(() => {
        fetchData();
        Aos.init();
    }, [])

    return(
        <>
         <section className="py-5">
                <Container fluid>
                    <div data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-center section-title my-5">Our Services</h2>
                        <div className="text-center" style={{fontSize:"20px",marginBottom:"80px"}}>
                            <p>We believe on-site personnel are the best form of crime prevention.</p>
                            <p>When you’re in need of security or site protection, our security professionals are available for short- and long-term engagements. Our officers are broadly screened and well-trained and can handle the responsibilities of unarmed and armed security. </p>
                        </div>                      
                    </div>
                    {
                        loader ? 
                        <>
                            <Row>
                                <Col lg="4">
                                    <Skeleton variant="rectangular" height={300}/>
                                    <Skeleton width="40%"/>
                                    <Skeleton/>
                                </Col>
                                <Col lg="4">
                                    <Skeleton variant="rectangular" height={300}/>
                                    <Skeleton width="40%"/>
                                    <Skeleton/>
                                </Col>
                                <Col lg="4">
                                    <Skeleton variant="rectangular" height={300}/>
                                    <Skeleton width="40%"/>
                                    <Skeleton/>
                                </Col>
                            </Row>
                        </> :

                        <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={true} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                            {
                                serviceData ? (
                                    serviceData.map((card,index) => (        
                                        <div key={`carouselcard-${index}${card.id}`} className="frame"  data-aos="zoom-in" data-aos-duration="1500">
                                            <img src={`${adminUrl}/uploads/service/${card.image}`} className="service-image" alt="carouselimage"/>
                                            <div className="text-wrapper">
                                                <h4>{card.title}</h4>
                                                <p>
                                                    {card.content}
                                                </p> 
                                                <NavLink to={`serviceview/${card.slug}`} className="text-danger redirect-link">Learn more <i className="fa-solid fa-arrow-right"></i></NavLink>
                                            </div>
                                        </div>    
                                    ))
                                ) : 
                                (
                                    serviceArray.map((card,index) => (        
                                        <div key={`carouselcard-${index}${card.id}`} className="frame"  data-aos="zoom-in" data-aos-duration="1500">
                                            <img src={`./assets/image/${card.src}`} alt="carouselimage"/>
                                            <div className="text-wrapper">
                                                <h4>{card.title}</h4>
                                                <p>
                                                    {card.content}
                                                </p> 
                                                <NavLink to={'about'} className="text-danger redirect-link" target="_blank">Learn more <i className="fa-solid fa-arrow-right"></i></NavLink>
                                            </div>
                                        </div>    
                                    ))
                                )
                            }
                        </ReactOwlCarousel>
                    }
                </Container>                    
            </section>
        </>
    ); 
}

