import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Skeleton } from "@mui/material";






export default function JobCard(props) {

    
    console.log(props.getData);
    
    const [loader, setLoader] = useState(true);

    

    useEffect(() => {
        // Simulate a loading delay
        const timer = setTimeout(() => {
            setLoader(false);
        },0); // Adjust the delay time as needed or replace with actual data fetching logic
        return () => clearTimeout(timer);
    },[]);    

    if(loader)
    {
        return <Skeleton height={110}/>
    }

    return (
        <React.Fragment>
            <HeadContainer>
                <HeadText><h5>Job Title</h5></HeadText>
                <HeadText><h5>Location</h5></HeadText>
                <HeadText><h5>Job Type</h5></HeadText>
                <HeadText><h5> </h5></HeadText>
            </HeadContainer>
            {
                props.getData ? props.getData.map((data) => (
                    <Card>
                        <div>
                            <i className="fa-solid fa-briefcase"></i>
                            <h5>{data.title}</h5>                                                                   
                        </div>
                        <div>
                            <h5>{data.location}</h5>
                        </div>
                        <div>
                            <h5>{data.type}</h5>
                        </div>
                        <ViewButton>
                            <NavLink to={`/careerview/${data.slug}`} className="eva-btn eva-outline-danger">View</NavLink>
                        </ViewButton>
                    </Card>
                )) : 
                (
                    <Card>
                        <div>
                            <i className="fa-solid fa-briefcase"></i>
                            <h5>Security Guard</h5>                                                                   
                        </div>
                        <div>
                            <h5>Rohtak</h5>
                        </div>
                        <div>
                            <h5>Guard</h5>
                        </div>
                        <ViewButton>
                            <NavLink className="eva-btn eva-outline-danger">View</NavLink>
                        </ViewButton>
                    </Card>
                )
            }
        </React.Fragment>
    )
};


const HeadContainer = styled.div`
    width: 100%;
    border: 9px;
    display: flex;
    justify-content: space-between;
    background-color: #454E9F;
    color: #fff;
    margin-bottom: 2em;
` 

const HeadText = styled.div`
    width:100%;
    padding: 10px 30px;
    text-align: left;

    h5{
        font-weight: 400; 
    }
    @media screen and (max-width:767px){
        h5{
            font-size: 15px;
        }
    }
    @media screen and (max-width:496px){
        padding: 14px 20px;

        h5{
            font-size: 14px;
        }

    }

` 
const Card = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0;
    border: 1px solid #CEC9C9;
    border-radius: 5px;

    div{
        display: flex;
        align-items: center;
        width:100%;
        padding: 10px 30px;
    }
  
    div i {
        margin-right: .5em;
    }

    @media screen and (max-width:767px){
        div h5{
            font-size: 15px;
        }
    }
    @media screen and (max-width:496px){
        div{
            padding: 10px; 
        }
    }

    @media screen and (max-width:379px){
        div h5{
            font-size: 12px;
        }
    }
    
`
const ViewButton = styled.div`
    display: flex;
    align-items: center;
    width:100%;
    padding: 10px 30px;
    justify-content: center;

    @media screen and (max-width:379px){
        button{
            font-size:12px;
        }
    }
`
