import React, { useCallback, useEffect, useState } from 'react';
import './service.css';
import { Container, Col } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Description = styled.div`
    margin-top: 1em;
`;


export default function ServiceViewPage() {
    const {slug} = useParams();

    const[serviceViewData, setServiceViewData] = useState(null);
    const[loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = useCallback (async () => {

        try{
            const response = await fetch(`${adminUrl}/api/service/${slug}`);
            const data = await response.json();
    
            setServiceViewData(data);
            console.log(data);
        }catch(error){
            console.error('Error fetching data', error);
        }finally{
            setLoader(false);
        }

    }, [slug]);
 
    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if(loader){
        return <Skeleton/>
    }
    
    return (
        <React.Fragment>
            <Helmet>
            <meta name="description" content={`Western Safety Patrol offer best ${serviceViewData.title} service.`}/>
            </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('../assets/image/bg-image-servicepage.jpg')"}}>
            {/* <div className="bg-image" style={{backgroundImage: "URL('assets/image/bg-image.png')"}}> */}
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">{serviceViewData ? serviceViewData.title : 'Service'}</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/service'} className="breadcrumb-item">Service</NavLink>
                            <NavLink to={'about'} className="breadcrumb-item active">{serviceViewData ? serviceViewData.title : 'Service'}</NavLink>
                        </div>
                    </Container>
                </div>
            </div>
            <section className='py-5' style={{overflow: "auto"}}>
                <Container fluid>
                        {
                            serviceViewData ? (
                                <Col md="12" lg="12">
                                    <div className="text-wrapper">
                                        {/* {textDescription(description.description)} */}
                                        <div className="certificate-image mx-2">
                                            <img src={`${adminUrl}/uploads/service/${serviceViewData.image}`} className="img-thumbnail" alt="certificate"/>
                                        </div>
                                        <h4 className='service-title'>{serviceViewData.title}</h4>
                                        <Description dangerouslySetInnerHTML={{ __html: serviceViewData.description }} />
                                    </div>
                                </Col>
                            ) : 
                            (
                                <React.Fragment></React.Fragment>
                            )
                        }
                </Container>
            </section>
            
           
        </React.Fragment>
    );
}

