import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap"
import {  NavLink } from "react-router-dom";
import CategoryCard from "../components/categorycard";
import ServiceCarousel from "../components/service-carousel";
import ContactForm from "../components/contact-form";
import Testimonial from "../components/testimonial";
import Director from "../components/director";
import CountUp from "react-countup";
import styled from "styled-components";
import Aos from "aos";
import 'aos/dist/aos.css';
import Client from "./ourclient/client";
import { Helmet } from "react-helmet";
import { Skeleton } from "@mui/material";
import { Margin } from "@mui/icons-material";
import Video from "../components/Video";




const Home = () => {

    const [apiData, setApiData] = useState(true);
    const [counter, setCounter] = useState(null);
    const [homepage, setHomepage] = useState(null);
    const [loader, setLoader] = useState(true);
    const [bestHeading, setBestHeading] = useState(null);
    const [aboutContent, setAboutContent] = useState(null);
    const [bestService, setBestService] = useState([]);

    const adminUrl = 'https://admin.wsp-india.com';

    // console.log(aboutContent);
    
    const fetchData = async () => {
        
        try {
            const [counterResponse, homepageResponse, bestServiceResponse, bestHeadingResponse, aboutContentResponse] = await Promise.all([
                fetch(`${adminUrl}/api/counterapi`).then((response) => response.json()),
                fetch(`${adminUrl}/api/homepage`).then((response) => response.json()),
                fetch(`${adminUrl}/api/bestService`).then((response) => response.json()),
                fetch(`${adminUrl}/api/heading`).then((response) => response.json()),
                fetch(`${adminUrl}/api/footercontent`).then((response) => response.json()),
            ]);
            setCounter(counterResponse);
            setHomepage(homepageResponse);
            setBestService(bestServiceResponse);
            setBestHeading(bestHeadingResponse);
            setAboutContent(aboutContentResponse);
            
        } catch (error) {
            console.error('Error fetching data:', error);
            setApiData(false);
        }
        finally{
            setLoader(false);
        }
    };
    
    useEffect(() => {
        Aos.init();
        fetchData();
    }, []);

    
    
    const serviceCard = [
        {
            id: '1',
            title: 'perfect anytime',
            icon: <i className="fa-solid fa-clock"></i>,
            description:
            'Our rapid response team action time is less than five to seven minutes to reach at defined service areas to take required action.',
        },
        {
            id: '2',
            title: '24/7 communication',
            icon: <i className="fa-solid fa-headset"></i>,
            description:
              `We are 24*7 hours available on our helplines to know our client's emergencies and give them best support.`,
        },
        {
            id: '3',
            title: 'our fleet',
            icon: <i className="fa-solid fa-car"></i>,
            description:
              `Rapid Response Team, Patrolling Officers, Ex- Military Trainers, Tech- Experts, Surveillance Experts, Detectives.`,
        },
        {
            id: '4',
            title: 'Advance Technology',
            icon: <i className="fa-solid fa-laptop"></i>,
            description:
              `We use State -of-art Technology, Officer scans QR-code placed on locations, the information is sent to the Web application via a cloud server including the Geo-Location in real time and client is also update via Whats app or E-mail.`,
        },
        {
            id: '5',
            title: 'Trained Officer',
            icon: <i className="fa-solid fa-user-tie"></i>,
            description:
              `Armed, unarmed, law enforcement security – we have the right guards for the job. We also provide specialty staffing in such areas as electronic surveillance, control room operations, retail and mall service, cash transaction escorts, and more.`,
        },
        {
            id: '6',
            title: 'Emergency Help',
            icon: <i className="fa-solid fa-fire-extinguisher"></i>,
            description:
              `We work round the clock for our client's satisfaction and employee care. Our trained staff is well capable to support in emergency situations whether Medical / Fire.`,
        },  
    ]

    if(loader){
        return <React.Fragment>
            <Container>
                
                <Skeleton variant="rectangular" height={40} sx={{margin: "84px 0 0 0"}}/>

                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={100} height={100} sx={{margin: "1em 0"}}/>
                <Skeleton variant="rectangular" height={60} sx={{margin: "1em 0"}}/>
                <Skeleton variant="rounded" height={60} sx={{margin: "1em 0"}}/>
            </Container>
        </React.Fragment>
    }
    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content="Western Safety Patrol"/>
            </Helmet>
            {/* Home banner section  */}
            <section className="home-banner"> 
                <div className="section-content">
                    
                    <Container fluid>
                        <Row className="align-items-center">
                            <Col sm="12" md="6" lg="6">
                                <div className="banner-content">
                                    <CompanyName>Western Safety <br/><span>Patrol-India</span><br/></CompanyName>
                                    <TagLine>Empowering Protection, Ensuring Peace: <br /> Your Trusted Surveillance Solution Partner</TagLine>
                                    
                                </div>
                            </Col>
                            <Col sm="12" md="6" lg="6" data-aos="flip-right" data-aos-duration="1500" className="d-flex justify-content-end">
                                {/* <ReactOwlCarousel className="owl-theme homepage-image"  responsive={responsiveOptions} margin={10} autoplayHoverPause={true} nav={true} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                                        {
                                            apiData && homepage ?  homepage.map((img) => (
                                                <div className="banner-profile margin-resp">
                                                    <img src={`https://wspwebapi.indutechit.com/uploads/homepage/${img.image}`} alt="securityteam"/>
                                                </div>
                                            )) : 
                                            (
                                                <div className="banner-profile margin-resp">
                                                    <img src="assets/image/security-team.jpg" alt="securityteam"/>
                                                </div>
                                            )
                                        }
                                </ReactOwlCarousel> */}
                                {
                                    apiData && homepage ? (
                                        <div className="banner-profile margin-resp">
                                            <img src={`${adminUrl}/uploads/homepage/${homepage.image}`} alt="securityteam"/>
                                        </div>
                                    ) : 
                                    (
                                        <div className="banner-profile margin-resp">
                                            <img src="assets/image/wsp-image.png" alt="securityteam"/>
                                        </div>
                                    )
                                }        
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>

            {/* About section start  */}
                <CategoryCard/>
            {/* About section end  */}

            <section className="py-5">
                <Container fluid>
                    <div className="rectangle-wrapper">
                        <div className="about-section" data-aos="flip-left" data-aos-duration="1500">
                            <h2>About us</h2>
                            {
                                apiData && aboutContent ? (
                                    aboutContent.content.length > 355 ? (
                                        <p>{`${aboutContent.content.substring(0, 358)}...`}</p>
                                    ) : 
                                    (
                                        <p>{aboutContent.content}</p>
                                    )
                                ) :
                                (
                                    <p>WSP provides a full suite of private contract security and patrol services with a customer-focused culture that is unique to the industry. We are able to tailor our offerings to clients’ needs due in-part to our well-trained personnel with varied backgrounds that include law enforcement, private security, customer service, and military experience.</p>
                                )
                            }
                            <NavLink to={'about'} className="eva-btn eva-btn-jasper text-uppercase">learn more..</NavLink>
                        </div>
                        <div className="number-section" data-aos="flip-right" data-aos-duration="1500">
                            <div className="frame">
                                <h2>Let’s number talk</h2>
                                {
                                    apiData && counter ? (

                                    <div className="number-count">
                                        <div className="count-down">
                                            <h1 className="count"><CountUp start={0} end={counter.cumulative_year} duration={2}/>+</h1>
                                            <p className="count-text">cumulative years in business</p>
                                        </div>
                                        <div className="count-down">
                                            <h1 className="count"><CountUp start={0} end={counter.happy_customers} duration={2}/>+</h1>
                                            <p className="count-text">Happy customers</p>
                                        </div>
                                        <div className="count-down">
                                            <h1 className="count"><CountUp start={0} end={counter.trained_officer} duration={2}/>+</h1>
                                            <p className="count-text">Trained officer</p>
                                        </div>
                                        <div className="count-down">
                                            <h1 className="count"><CountUp start={0} end={counter.asset_protected} duration={2}/>+</h1>
                                            <p className="count-text">assets protected</p>
                                        </div>
                                    </div>
                                    ) : 
                                    (
                                        <div className="number-count">
                                            <div className="count-down">
                                                <h1 className="count"><CountUp start={0} end={80} duration={2}/>+</h1>
                                                <p className="count-text">cumulative years in business</p>
                                            </div>
                                            <div className="count-down">
                                                <h1 className="count"><CountUp start={0} end={250} duration={2}/>+</h1>
                                                <p className="count-text">Happy customers</p>
                                            </div>
                                            <div className="count-down">
                                                <h1 className="count"><CountUp start={0} end={500} duration={2}/>+</h1>
                                                <p className="count-text">Trained officer</p>
                                            </div>
                                            <div className="count-down">
                                                <h1 className="count"><CountUp start={0} end={2000} duration={2}/>+</h1>
                                                <p className="count-text">assets protected</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

             {/* Services  */}
            <section className="py-5" style={{backgroundColor: "#f8f9fa"}}>
                <Container fluid>
                    <h2 className="text-center section-title"  data-aos="fade-up" data-aos-duration="1500">{apiData && bestHeading ? bestHeading.heading : 'We Provide The Best Services'}</h2>
                    <Row>
                        {
                            apiData && bestService ? (
                                bestService.map((service) => (
                                    <Col key={service.id} sm="12" md="4" lg="4">
                                        <div className="container-wrapper"  data-aos="fade-up" data-aos-duration="1500">
                                            <div className="text-center icon">
                                                <div dangerouslySetInnerHTML={{__html: service.icon}}/>
                                            </div>
                                            <div className="label">
                                                <h4 className="label-content">{service.title}</h4>
                                                <p className="label-paragraph">
                                                    {service.content}
                                                </p>
                                                {/* <NavLink to={'about'} className="eva-btn eva-btn-jasper text-uppercase">learn more..</NavLink> */}
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            ) :
                            (
                                serviceCard.map((card, index) => (
                                    <Col key={index} sm="12" md="4" lg="4">
                                        <div className="container-wrapper"  data-aos="fade-up" data-aos-duration="1500">
                                            <div className="text-center icon">
                                                {card.icon}
                                            </div>
                                            <div className="label">
                                                <h4 className="label-content">{card.title}</h4>
                                                <p className="label-paragraph">
                                                    {card.description}
                                                </p>
                                                {/* <NavLink to={'about'} className="eva-btn eva-btn-jasper text-uppercase">learn more..</NavLink> */}
                                            </div>
                                        </div>
                                    </Col>
                                ))
                            )
                        }
                    </Row>
                </Container>
            </section>

            {/* Service Carousel Start */}
                <ServiceCarousel />
            {/* Service Carousel End */}
            

            {/* Video Section Start */}
            <Video/>
            {/* Video Section End */}



            {/* Testimonial and Contact section Start */}
            <section id="sectionBackground" className="bg-section py-4" style={{backgroundImage: "url('assets/image/rectangle-30.png')", backgroundRepeat:"no-repeat", backgroundSize:"cover"}}  data-aos="fade-up" data-aos-duration="1500">
                <Container fluid>
                    <Row style={{position: 'relative', zIndex: '99'}}>
                        <Col md="6" lg="5" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                            <h2 className="text-center section-title my-5">Testimonial</h2>
                            <div className="testimonial-container">
                                <Testimonial/>
                            </div>
                        </Col>
                        <Col md="0" lg="2" className="column-hide"></Col>
                        <Col md="6" lg="5" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
                            <div className="title-container">
                                <h2 className="text-center section-title mt-5">Request Info</h2>
                                <p className="">Feel free to contact us for any query </p>
                            </div>
                            <div className="contact-form">
                                <ContactForm/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Testimonial and Contact section End */}



            {/* Client Carousel Start */}
             <Client/>
            {/* Client Carousel End */}
            


            {/* Director Start */}
            <Director/>
            {/* Director End */}



        </React.Fragment>
    )
}

export default Home;



const CompanyName = styled.h3`
    font-size: 75px;
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 38px;
    color: var(--main-color);

    span{
        font-weight: 600;
        color: var(--primary-color);
    }

    @media (max-width: 1252px) { font-size: 70px; }
    @media (max-width: 1173px) { font-size: 64px; }
    @media (max-width: 1080px) { font-size: 58px; }
    @media (max-width: 984px) { font-size: 52px; }
    @media (max-width: 891px) { font-size: 45px; }
    @media (max-width: 781px) { font-size: 40px; }
    @media (max-width: 766px) { font-size: 50px; }
`;

const TagLine = styled.p`
    font-family: var(--paragraph);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 400;
    color: #fff;

    @media (max-width: 1200px) { font-size: 17px; }
    @media (max-width: 991px) { font-size: 13px; }
    @media (max-width: 768px) { font-size: 18px; }
    @media (max-width: 575px) { font-size: 15px; }
    @media (max-width: 525px) { font-size: 13px; }
    @media (max-width: 480px) { font-size: 12px; }
    @media (max-width: 352px) { font-size: 10px; }

`