import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import TopArrowButton from "./TopArrowButton";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import { styled } from "@mui/material/styles";

const CustomLink = styled('a')({
    textDecoration: 'none',
    margin: '0.5em',
    '& img': {
        width: '159px',
        height: '48px',
        '&:hover': {
            color: '#fff',
           
        },
    },
    '& .android-btn':{
        transform: 'translateY(0px)',
        transition: 'all, .5s ease-in !important',

        '&:hover':{
            transform: 'translateY(-2px)',
            boxShadow: '0px 0px 16px -10px rgba(255,255,255,0.6)',
        }
    }
});



export default function Footer(){

    const [profileData, setProfileData] = useState(null);
    const [whatsapp, setWhatspp] = useState(null);
    const [addressData, setAddressData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [apiData, setApiData] = useState(true);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
        try {
        // Fetch data from all APIs simultaneously
        const [profileResponse, addressResponse, contactResponse, serviceResponse, whatsappResponse] = await Promise.all([
            fetch(`${adminUrl}/api/profile`).then((response) => response.json()),
            fetch(`${adminUrl}/api/address`).then((response) => response.json()),
            fetch(`${adminUrl}/api/contact`).then((response) => response.json()),
            fetch(`${adminUrl}/api/service`).then((response) => response.json()),
            fetch(`${adminUrl}/api/whatsapp`).then((response) => response.json()),
        ]);
        
        // console.log("ProfileData: ",profileResponse);
        // console.log("AddressData: ",addressResponse);
        // console.log("ContactData: ",contactResponse);

        setProfileData(profileResponse);
        setAddressData(addressResponse);
        setContactData(contactResponse);
        setServiceData(serviceResponse);
        setWhatspp(whatsappResponse);

        } catch (error) {
            console.error('Error fetching data:', error);
            setApiData(false);
        // Handle errors, set appropriate states or display error messages
        }finally{
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const redirectToWhatsapp = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsapp.whatsapp_number)}`;
        window.open(whatsappUrl, '_blank');
    };



    if(loader){
        return <Skeleton/>
    }

    return(
        <footer className="bg-dark py-5">
           <Container fluid>
                <Row>
                    <Col sm="12" md="4" lg="4">
                        {
                       
                        apiData && profileData ? (
                        <React.Fragment>
                            <div className="footer-brand">
                                <img src={`${adminUrl}/uploads/profile/${profileData.company_logo}`} alt="companylogo"/>
                            </div>
                            <p>{profileData.content}</p>
                        </React.Fragment>
                        ) : 
                        (
                        <React.Fragment>
                            <div className="footer-brand">
                                <img src="assets/image/logo.png" alt="companylogo"/>
                            </div>
                            <p>
                                WSP provides a full suite of private contract security and patrol services with a customer-focused culture that is unique to the industry.
                            </p>
                        </React.Fragment>
                        )
                        
                        }
                        <div className="social-link my-3">
                            {
                                apiData && profileData ? (
                                    <>
                                    <NavLink to={profileData.company_fb_link} target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                    <NavLink to={profileData.company_instagram_link} target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                                    <NavLink to={profileData.company_twitter_link} target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                    <NavLink to={profileData.company_linkedin_link} target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                    <Link onClick={redirectToWhatsapp} target="_blank"><i className="fa-brands fa-whatsapp"></i></Link>
                                    </>
                                ): 
                                (
                                    <>
                                    <NavLink to={"https://www.facebook.com/"} target="_blank"><i className="fa-brands fa-facebook-f"></i></NavLink>
                                    <NavLink target="_blank"><i className="fa-brands fa-instagram"></i></NavLink>
                                    <NavLink target="_blank"><i className="fa-brands fa-x-twitter"></i></NavLink>
                                    <NavLink target="_blank"><i className="fa-brands fa-linkedin-in"></i></NavLink>
                                    <NavLink target="_blank"><i className="fa-brands fa-whatsapp"></i></NavLink>
                                    </>
                                )
                            }
                        </div>
                        <CustomLink href={`https://play.google.com/store/apps/details?id=com.wsppatrolemployee `} target="_blank" rel="noopener noreferrer">
                            <img src="assets/image/play_store.png" className="android-btn" alt=""/>
                        </CustomLink>
                        <CustomLink href="https://apps.apple.com/in/app/western-safety-patrol/id6502806040" target="_blank" rel="noopener noreferrer">
                            <img src="assets/image/app_store.png" className="app-store" alt="play store"/>
                        </CustomLink>
                    </Col>
                    <Col sm="12" md="4" lg="4" className="container-center">
                        <div className="margin-resp">
                            <h4 className="footer-title">Services</h4>
                            <ul className="footer-nav-list">
                            {
                                apiData && serviceData ? (
                                    serviceData.map((data,index) => (
                                        <li className="footnav-link" key={index}><NavLink to={`serviceview/${data.slug}`}>{data.title}</NavLink></li>
                                    ))
                                ) : 
                                (
                                    <>
                                    <li className="footnav-link"><NavLink>security officers</NavLink></li>
                                    <li className="footnav-link"><NavLink>vehicle patrol</NavLink></li>
                                    <li className="footnav-link"><NavLink>alarm & incident response</NavLink></li>
                                    <li className="footnav-link"><NavLink>event security</NavLink></li>
                                    <li className="footnav-link"><NavLink>transportation</NavLink></li>
                                    <li className="footnav-link"><NavLink>security consultant</NavLink></li>
                                    <li className="footnav-link"><NavLink>Privacy Policy</NavLink></li>
                                    </>
                                )
                            }
                            <li className="footnav-link"><NavLink to={'policy'}>Privacy Policy</NavLink></li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm="12" md="4" lg="4">
                        <div className="margin-resp">
                            <h4 className="footer-title">Address</h4>

                            <ul className="footer-nav-list">
                                {
                                    apiData && addressData ? 
                                    addressData.map((address) => (
                                        <li key={`address${address.id}`} className="footnav-link"><NavLink to={address.googlemap_link} target="_blank" rel="noopener noreferrer"><strong>{address.address_title}</strong> {`- ${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}</NavLink></li>
                                    )) :
                                    (
                                        <>
                                            {/* Manually entered data when API data is not available */}
                                            <li className="footnav-link">
                                                <NavLink>
                                                    <strong>Regd. Office</strong> – Tilak Nagar, 591 B, Shop No 1, Rohtak, Haryana, 124001
                                                </NavLink>
                                            </li>
                                        </>
                                    )
                                }
                                
                                <li className="footnav-link">
                                {
                                    apiData && contactData ? contactData.map((contact) => (
                                        <>
                                            <NavLink key={`contact0${contact.id}`} to={`tel:${contact.contact_number}`}><strong><i className="fa-solid fa-phone"></i></strong>{`  ${contact.contact_number} (${contact.contact_title})`}</NavLink><br />
                                        </>
                                        )
                                    ) : 
                                    (
                                        <>
                                            <NavLink to={"tel:9254029411"}><strong><i className="fa-solid fa-phone"></i></strong> - 92540-29411 (Sales)</NavLink><br />
                                            <NavLink to={"tel:7056740202"}><strong><i className="fa-solid fa-phone"></i></strong> - 70567-40202 (Helpline)</NavLink>
                                        </>
                                    )
                                }

                                
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <hr className="text-white mt-4"/>
                    <Col lg={12} className="text-center">
                        <div className="copyright text-white">
                            &copy; Copyright 
                        </div>
                        <div className="credits text-white ">
                            All Rights Reserved by <strong className="">Western Safety Patrol</strong> 2024	&copy;
                        </div>
                    </Col>
                </Row>
            </Container> 
            <TopArrowButton/>
        </footer>
    );
}