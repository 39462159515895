import React, { useState, useEffect, useRef } from "react";
import backgroundvideo from '../video/Presentation.mp4';
import styled from "styled-components";
import { apiDomain } from "../Api";


export default function Video(){

    const [play, setPlay] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const videoRef = useRef(null);

    useEffect(() => {
        const fetchVideoData = async () => {
            try {
                const response = await fetch(`${apiDomain}/api/video`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // console.log('Fetched data:', data); // Log the fetched data
                setVideoData(data);
            } catch (error) {
                console.error('Error fetching video data:', error);
            }
        };

        fetchVideoData();
    }, []);
    


    useEffect(() => {
        if (videoRef.current) {
            const video = videoRef.current;

            const handleVideoEnd = () => {
                setPlay(false);
            };

            video.addEventListener('ended', handleVideoEnd);

            return () => {
                video.removeEventListener('ended', handleVideoEnd);
            };
        }
    }, [videoData]);

    function handlePlay() {
        if (play) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setPlay(!play);
    }

    return (
        <React.Fragment>
            <Section className="position-relative">
                {
                    videoData && (
                        <>
                            <VideoClip ref={videoRef} src={`${apiDomain}/uploads/videos/${videoData.video_file}`} ></VideoClip>
                            <PlayButton onClick={handlePlay}><i className={`fa-solid fa-${play ? 'pause' : 'play'}`}></i></PlayButton>
                        </>
                    )
                }
            </Section>
        </React.Fragment>
    )
}


const VideoClip = styled.video`
    width: 100%;
    height: auto;
`;

const Section = styled.section`

    &::after{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.6); 
    }
`;

const PlayButton = styled.button`
    position: absolute;
    top: 40%;
    left: 48%;
    width: 0px;
    aspect-ratio: 1/1;
    font-size: 50px;
    color: #fff;
    border: none;
    z-index: 99;
    transition: all .5s ease-in-out;

    
    &:active{
        background-color: #fff;
    }
`;