import './about.css';
import { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';
// import {CountUp} from 'react-countup/build/CountUp';
// import { useCountUp } from 'react-countup';
import CountUp from 'react-countup';
import Director from '../../components/director';
import CertificateGallery from '../../components/CertificateGallery';
import ReactOwlCarousel from "react-owl-carousel";
import { Helmet } from 'react-helmet';



export default function About(){

    const [apiData, setapiData] = useState(true);
    const [counter, setCounter] = useState(null);
    const [description, setDescription] = useState('');
    const [imageSlider, setImageSlider] = useState([]);

    const adminUrl = 'https://admin.wsp-india.com';

    // OwlCarousel Responsive 
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };

    // Director fetch API data
    const fetchDirectorData = async () => {
        try {
          const [counterResponse, description, imageSliderResponse ] = await Promise.all([
            fetch(`${adminUrl}/api/counterapi`).then((response) => response.json()),
            fetch(`${adminUrl}/api/description`).then((response) => response.json()),
            fetch(`${adminUrl}/api/imageslider`).then((response) => response.json()),
          ]);
          setCounter(counterResponse);
          setDescription(description);
          setImageSlider(imageSliderResponse);

        } catch (error) {
          console.error('Error fetching data:', error);
          setapiData(false);
        }
    };
    
    useEffect(() => {
        fetchDirectorData();
        Aos.init();
    }, []);

    

    

    
    return (
        <>
        <Helmet>
            <meta name="description" content="WSP provides a full suite of private contract security and patrol services with a customer-focused culture that is unique to the industry. We are able to tailor our offerings to clients’ needs due in-part to our well-trained personnel with varied backgrounds that include law enforcement, private security, customer service, and military experience."/>
        </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('assets/image/bg-image.png')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid> 
                        <h1 className="page-name">About</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/'} className="breadcrumb-item">Home</NavLink>
                            <NavLink to={'about'} className="breadcrumb-item active"> About</NavLink>
                        </div>
                    </Container>
                </div>
            </div>
            <section className="aboutpage-section" data-aos="fade-up" data-aos-duration="1500">
                <Container className="py-5" fluid>
                    <Row>
                        {
                            description ? (
                                <Col md="12" lg="12">
                                    <div className="text-wrapper">
                                        {/* {textDescription(description.description)} */}
                                        <div className="certificate-image mx-2" data-aos="flip-right" data-aos-duration="1500">
                                            <ReactOwlCarousel className="owl-theme homepage-image" responsive={responsiveOptions} margin={10} autoplayHoverPause={true} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                                                {
                                                    apiData && imageSlider.map((data, index) => (
                                                        <img key={`image-${index}${data.id}`} src={`${adminUrl}/uploads/aboutimage/${data.image}`} className="img-thumbnail slider" alt="certificate"/>
                                                    ))
                                                }
                                            </ReactOwlCarousel>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: description.description }} />
                                    </div>
                                </Col>
                            ) :
                            (
                                <Col md="12" lg="12">
                                    <div className="text-wrapper">
                                        <div className="certificate-image mx-2" data-aos="flip-right" data-aos-duration="1500">
                                            {/* <ReactOwlCarousel className="owl-theme homepage-image" responsive={responsiveOptions} margin={10} autoplayHoverPause={true} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                                                <img src="assets/image/event-security1.jpg" className="img-thumbnail" alt="certificate"/>
                                            </ReactOwlCarousel> */}
                                            <img src="assets/image/event-security1.jpg" className="img-thumbnail" alt="certificate"/>
                                        </div>
                                        <h2 className="about-title">DIFFERENTIATION</h2>
                                        <p>
                                            Disciplined adherence to core values of service, responsiveness, and local presence.
                                            We take providing protective services very seriously, as we are responsible for the protection of people, property, and assets. We work tirelessly every day to build customer loyalty by consistently providing every client with the security, responsiveness, and service they need, delivered by highly dedicated and professionally trained officers.
                                            We are committed to the safety of our clients and their employees and, at the same time, place the highest value on our relationships with clients.
                                        </p>
                                        <p><strong>Core Values</strong></p>
                                        <ul className="about-list">
                                            <li>Local Presence</li>
                                            <li>Responsiveness</li>
                                            <li>Employees/Workers Safety</li>
                                        </ul>
                                        <p><strong>Safety and Professional</strong></p>
                                        <p>
                                            Operational excellence is a hallmark of our service. Our standards ensure utmost safety for our clients and their employees. Our organization is uniquely structured so that each division has a leader with ownership interest. This engenders a superior level of care and responsiveness in daily operations as well as customer alignment.
                                        </p>
                                        <p>
                                            <strong>We adhere to all Govt. Procedures, Compliance and policies whereas applicable.</strong>
                                        </p>
                                    </div>
                                </Col>                       
                            )
                        }
                    </Row>
                </Container>
            </section>

            <section className="bg-light">
                <Container fluid>
                    <div className="counterbar">
                        <Row>
                            <Col lg="3" md="6" sm="6">
                                <div className="count-wrapper">
                                    { apiData && counter ? (
                                        <h2><CountUp start={0} end={counter.cumulative_year} duration={2}/>+</h2>
                                        ) : (
                                        <h2><CountUp start={0} end={100} duration={2}/>+</h2>
                                        )
                                    }
                                    <p>Cumulative Years in Business</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <div className="count-wrapper">
                                    { apiData && counter ? (
                                        <h2><CountUp start={0} end={counter.happy_customers} duration={2}/>+</h2>
                                        ) :
                                        (
                                            <h2><CountUp start={0} end={500} duration={2}/>+</h2>
                                        )
                                    }
                                    <p>Happy customers</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <div className="count-wrapper">
                                    { apiData && counter ? (
                                        <h2><CountUp start={0} end={counter.trained_officer} duration={2}/>+</h2>
                                        ) :
                                        (
                                            <h2><CountUp start={0} end={50} duration={2}/>+</h2>
                                        )
                                    }
                                    <p>Trained officer</p>
                                </div>
                            </Col>
                            <Col lg="3" md="6" sm="6">
                                <div className="count-wrapper">
                                    { apiData && counter ? (
                                        <h2><CountUp start={0} end={counter.asset_protected} duration={2}/>+</h2>
                                        ) :
                                        (
                                            <h2><CountUp start={0} end={2000} duration={2}/>+</h2>
                                        )
                                    }
                                    <p>Assets Protected</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="my-3">
                <Container fluid>
                    <CertificateGallery/>
                </Container>
            </section>
             
            <Director/>
            
            
            {/* {
                apiData && directorData ? (
                    <section className="bg-light py-5">
                        <Container>
                            <Row className="align-items-center">
                                <h4 className="text-center mb-3">Director Vision</h4>
                                <Col sm="12" md="4">
                                    <div className="content-wrapper">
                                        <div className="profile-md">
                                            <img src={`https://wspwebapi.indutechit.com/uploads/profile/${directorData.director_photo}`} alt="mdprofile"/>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="12" md="8">
                                    <div className="content-text">
                                        <p className="vision-content">{directorData.director_discription}</p>
                                        <p className="director-name">{directorData.director_name} - Director</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                ) : 
                (
                    <section className="bg-light py-3">
                        <Container>
                            <Row className="align-items-center">
                                <h4 className="text-center mb-3">Director Vision</h4>
                                <Col sm="12" md="4">
                                    <div className="content-wrapper">
                                        <div className="profile-md">
                                            <img src="assets/image/md-profile.jpg" alt="mdprofile"/>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm="12" md="8">
                                    <div className="content-text">
                                        <p className="vision-content">
                                            Western safety has consolidated it’s position as a leader in safety patrol and integrated surveillance solutions. Our standards ensure utmost safety for clients and their employees. WSP aims to expend globally, while seeking sustainable ways to engage with clients.
                                        </p>
                                        <p className="director-name">Sandeep Dahiya - Director</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                )
            } */}
        </>
    );
}