import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import ReactOwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Aos from "aos";
import 'aos/dist/aos.css';


const Section = styled.section`
    padding: 3em 0;

    .owl-stage{
        display: flex;
        align-items: center;
    }
    .owl-stage .owl-item{
        display: flex;
        justify-content: center;
    }
`;

const ClientImage = styled.div`
    width: 200px;
    display: flex;
    align-items:center;
    filter: grayscale(1);
    
    &:hover{
        filter: grayscale(0);
    }
`;

const Heading = styled.h2`
    font-size: 36px;
    font-family: var(--heading);
    color: var(--main-color);
    font-weight: 700;
    text-transform: capitalize;
    margin: 50px 0;
`;

export default function Client(){

     // OwlCarousel Responsive //
     const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 3, // Show 2 cards at 500px and up
        },
        1000: {
            items: 4, // Show 3 cards at 1000px and up
        },
    };

    const ClientData = [
        {
            id: 1,
            title: 'Crimsoune Club',
            image: 'crimsoune-club-logo.jpeg',
        },
        {
            id: 2,
            title: 'GM Foods',
            image: 'gm-food-logo.jpg',
        },
        {
            id: 3,
            title: 'K K Lights',
            image: 'kk-lights-logo.jpeg',
        },
        {
            id: 4,
            title: 'Natraj Stationary',
            image: 'natraj-logo.png',
        }
    ];

    
    const [data, setData] = useState([]);
    
    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
    try{
        const response = await fetch(`${adminUrl}/api/client`);
        const datad = await response.json();
        setData(datad);
        // console.log("API DATA False", datad);
        }catch(error){
            console.error('error fetching data', error);
        }
    }

    
    useEffect(() => {
        fetchData();
        console.log("ClientData :",data);
        // Aos.init();
    }, []);


    // if (!data.length) return (
    //     <React.Fragment>
    //         <Section>
    //             <Container fluid>
    //                 <Heading className="text-center section-title">Our Client</Heading>
    //                 <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
    //                     {
    //                         ClientData.map((card,index) => (        
    //                             <ClientImage key={`clieo-${index}`}>
    //                             <img src={`assets/image/client-logo/${card.image}`} alt={card.title} />
    //                             </ClientImage>
    //                         ))
    //                     }
    //                 </ReactOwlCarousel>
    //             </Container>
    //         </Section>
    //     </React.Fragment>
    // );

    return(
        <React.Fragment>
            <Section>
                <Container fluid>
                    <Heading className="text-center section-title">Our Client</Heading>
                    {data.length &&
                        <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                            {
                                data.length && 
                                data.map((card,index) => (        
                                    <ClientImage key={`clie-${index}`}>
                                    <img src={`${adminUrl}/uploads/client/${card.image}`} alt={card.title} />
                                    </ClientImage>
                                ))
                            }
                        </ReactOwlCarousel>
                    }
                </Container>
            </Section>
        </React.Fragment>
    )
}


