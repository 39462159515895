import { NavLink, Link, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { Helmet } from "react-helmet";






export default function CareerPage(){

    const { slug } = useParams();

    const[apiData, setApiData] = useState(true);
    const[careerPage, setCareerPage] = useState(null);
    const[loader, setLoader] = useState(true);
    
    const adminUrl = 'https://admin.wsp-india.com';
    
    const fetchData = useCallback (async () => {
        
        try{
            const response = await fetch(`${adminUrl}/api/career`);
            const data = await response.json();
            
            const matchSlug = data.find(item => item.slug === slug);
            
            // console.log("MATCHSLUG: APP: ", matchSlug);
            setCareerPage(matchSlug);
        }catch(error){
            // console.error('Error fetching data', error);
            setApiData(false)
        }finally{
            setLoader(false);
        }
        
    }, [slug]);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    
    // const slugName = careerPage.title;

    // console.log("Slug Name:",slugName);
    const pageId = careerPage?.id;
    const careerInfo = careerPage;
    // console.log("Careepage Id: ", pageId, {careerInfo});

    if(loader){
        return (
            <>
            <div className="bg-image" style={{backgroundImage: "URL('/assets/image/dark-theme-background.jpg')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">Career Page</h1>
                        <div className="breadcrumb">
                            <NavLink to={'career'} className="breadcrumb-item">Career</NavLink>
                            <Link className="breadcrumb-item active">{ apiData && careerPage ? careerPage.title : 'Career Page'}</Link>
                        </div>
                    </Container>
                </div>
            </div>
            <section className="section">
                <Container fluid>
                    <Skeleton/>
                </Container>
            </section>
            </>
        )
    }

    return(
        <>
        <Helmet>
            <meta name="description" content="Western Safety Patrol provides customized security services with a focus on customers. Our team, including ex-police and military members, keeps you safe."/>
        </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('/assets/image/dark-theme-background.jpg')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">{ apiData && careerPage ? careerPage.title : 'Career Page'}</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/career'} className="breadcrumb-item">Career</NavLink>
                            <Link className="breadcrumb-item active">{ apiData && careerPage ? careerPage.title : 'Career Page'}</Link>
                        </div>
                    </Container>
                </div>
            </div>
            <Section>
                <Container fluid>
                    <Row>
                        <Col md="6">
                            <LeftHeroSection>
                                <HeroTitle className="hero-title">{ apiData && careerPage ? careerPage.title : 'Job Title'}</HeroTitle>
                            </LeftHeroSection>
                        </Col>
                        <Col md="6">
                            <HeroImage>
                                <img className="hero-image" src="/assets/image/vacancy.jpg" alt="hero-img"/>
                            </HeroImage>
                        </Col>
                        <Col sm="12" lg="12">
                            <Row>
                                <Col sm="12" ms="12" lg="4">
                                    <SectionTitle>
                                        <h2>Job Title</h2>
                                        <p>{careerPage.title}</p>
                                    </SectionTitle>
                                </Col>
                                <Col sm="12" ms="12" lg="4">
                                    <SectionTitle>
                                        <h2>Job Type</h2>
                                        <p>{careerPage.type}</p>
                                    </SectionTitle>
                                </Col>
                                <Col sm="12" ms="12" lg="4">
                                    <SectionTitle>
                                        <h2>Experience</h2>
                                        <p>{careerPage.experience}</p>
                                    </SectionTitle>
                                </Col>
                                <Col sm="12" ms="12" lg="4">
                                    <SectionTitle>
                                        <h2>Salary</h2>
                                        <p>{careerPage.salary}</p>
                                    </SectionTitle>
                                </Col>
                                <Col sm="12" ms="12" lg="4">
                                    <SectionTitle>
                                        <h2>Location</h2>
                                        <p>{careerPage.location}</p>
                                    </SectionTitle>
                                </Col>
                            </Row>
                            <div dangerouslySetInnerHTML={{ __html: careerPage.description }} />
                            <NavLink to={'/careerform/applicant'} state={careerInfo} className="eva-btn eva-btn-primary my-3">Apply Now</NavLink>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
};


export const Section = styled.div`
    padding: 3em 0;

`
const HeroTitle = styled.h1`
    font-size:84px;
    color: var(--main-color);

    @media screen and (max-width:379px){
        font-size: 62px;
    }
    @media screen and (max-width:245px){
        font-size: 52px;
    }
`
const LeftHeroSection = styled.div`
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const HeroImage = styled.div`
    width: 100%;
    display: flex;
    justify-content: end;
    position: relative;

    div.object{
        width: 500px;
        height: 500px;
        backgroung-color: #3962A1;
        
    }

    img{
        width: 100%;
    }
`
const SectionTitle = styled.div`
    margin-bottom: 5em;

    h2{
        margin-bottom: .2em;
        color: var(--primary-color);
    }
    p{
        font-size: 20px;
        font-weight: 500;
        color: var(--main-color);
    }
    @media screen and (max-width:990px){
        margin-bottom: 2em;
    }
`