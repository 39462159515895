import { Container } from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Aos from "aos";
import 'aos/dist/aos.css';



export default function CategoryCard(){

    const [apiData, setApiData] = useState(true);
    const[category, setCategory] = useState([]);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {

        try{
            const response = await fetch(`${adminUrl}/api/servicecategory`);
            const data = await response.json();
    
            setCategory(data);
        }catch(error){
            console.error('Error fetching data', error);
            setApiData(false);
        }

    };

    useEffect(() => {
        Aos.init();
        fetchData();
    }, []);
    
    const card = [
        {
            id: 1,
            icon: <i className="fa-solid fa-industry"></i>,
            title: "Industrial Security",
            list: [
                "Commercial Entities",
                "Hotels",
                "Industrial Sites",
                "Logistics & Warehouse",
                "parking Lots",
                "Trucks yards"
            ]
        },
        {
            id: 2,
            icon: <i className="fa-solid fa-building"></i>,
            title: "Commercial Security",
            list: [
                "Commercial Sites",
                "corporate building",
                "mall & plazas",
                "Logistics & Warehouse",
                "parking Lots",
                "Trucks yards"
            ]
        },
        {
            id: 3,
            icon: <i className="fa-solid fa-warehouse"></i>,
            title: "Residential Security",
            list: [
                "apartment building",
                "alarm response",
                "condominiums",
                "mobile patrol",
                "Residential complexes",
                "retails"
            ]
        },
    ];


    return( 
        <>
            <section className="py-5 category-section"  data-aos="fade-up" data-aos-duration="1500">
                <Container fluid>
                    <div className="box"> 
                        {
                            apiData && category ? (
                                category.map((card, index) => (
                                    <div key={`service-${card.id}`} className="rectangle" data-aos="zoom-in" data-aos-duration="1500">
                                        <div className="frame">
                                            <div className="warehouse"><div dangerouslySetInnerHTML={{__html: card.icon}}/></div>
                                            <div className="text-wrapper">
                                                {card.category}
                                            </div>
                                            <ul className="check-list">
                                                {card.service_area.map((listitem, index) => <li key={`listitem-${index}`}><i className="fa-solid fa-check"></i> {listitem.service_area}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            ) :
                            (
                                card.map((card, index) => (
                                    <div key={`service-${card.id}`} className="rectangle" data-aos="zoom-in" data-aos-duration="1500">
                                        <div className="frame">
                                            <div className="warehouse">{card.icon}</div>
                                            <div className="text-wrapper">
                                                {card.title}
                                            </div>
                                            <ul className="check-list">
                                                {card.list.map((listitem, index) => <li key={`listitem-${index}`}><i className="fa-solid fa-check"></i> {listitem}</li>)}
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            )
                        }
                    </div>
                </Container>
            </section>
        </>
    );
}