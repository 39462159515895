import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";


const ErrorPage = () => {

    return(
        <>
            <section className="bg-dark">
                <Container fluid>
                    <Row className="d-flex justify-content-center align-items-center height-vh">
                        <Col lg="6" className="col-12">
                            <Col md="12">
                                <img src="/assets/image/error-img.png" width="100%" alt="404img"/>
                            </Col>
                        </Col>
                        <Col lg="6" className="col-12 error-page-content">
                            <Col className="col-12 d-flex flex-column justify-content-center align-items-center">
                                <h1 className="main-heading">404</h1>
                                <h2>we couldn't find this page.</h2>
                                <div className="text-center mt-4 mb-5">
                                    <NavLink to={'/'} className="btn btn-success px-3 mx-2"><i className="fa fa-home"></i> Home</NavLink>
                                    <NavLink to={'contact'} className="btn btn-success px-3 mx-2"><i className="fa fa-phone"></i> Contact</NavLink>
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );

}

export default ErrorPage;