
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback, createContext } from 'react';
import Header from './include/header';
import Home from './page/home';
import About from './page/about/about';
import Service from './page/service/services';
import Footer from './include/footer';
import ContactPage from './page/contact/contact-page';
import ScrollOnTop from './components/ScrollOnTop';
import ErrorPage from './components/ErrorPage';
import ServiceViewPage from './page/service/serviceview-page';
import Career from './page/career/career';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './Style/GlobalStyle';
import CareerPage from './page/career/CareerPage';
import Applicant from './page/career/Applicant';
import Policy from './components/privacyPolicy';
import { Helmet } from 'react-helmet';
import MobileAppPage from './components/MobileAppPage';


export const DataContext = createContext(null);


function App() {


  const theme = {
    colors: {
      primary_color: '#D43647',
      hover_primary_color: '#f7021b',
      main_color: '#454E9F',
    },
    fontFamily: {
      heading: 'Montserrat',
      paragraph: 'Inter',
      highlight: 'Montserrat',
    },
    media: {
      tab: '998px',
      mobile: '767px',
      sm_mobile: '576px',
      xsm_mobile: '372px',
    }
  }

  
  const[career, setCareer] = useState(null);
  

  const adminUrl = 'https://admin.wsp-india.com'

  const {slug} = useParams();
  const fetchData = useCallback (async () => {

      try{
          const response = await fetch(`${adminUrl}/api/career`);
          const data = await response.json();
          
          const matchSlug = data.find(item => item.slug === slug);

          setCareer(data);
          // console.log("Career Data: ", data);
      }catch(error){
          console.error('Error fetching data', error);
      }

  }, [slug]);

  useEffect(() => {
      fetchData();
  }, [fetchData]);

  

  return (
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <Helmet>
          <meta id="og-title" property="og:title" content="Western Safety Patrol" />
          <meta id="og-image" property="og:image" content="assets/image/logo.png" />
          <meta name="keyword" content="Safety, Security, Patrolling, Residential Security, Industrial Security"/>
        </Helmet>
        <DataContext.Provider value={career}>
          <BrowserRouter>
            <Header/>
            <ScrollOnTop/>
            <Routes>
              <Route path='/' Component={Home}/>
              <Route path='about' Component={About}/>
              <Route path='service' Component={Service}/>
              <Route path='serviceview/:slug' Component={ServiceViewPage}/>
              <Route path='career' Component={Career}/>
              <Route path='careerview/:slug' Component={CareerPage}/>
              <Route path='contact' Component={ContactPage}/>
              <Route path='careerform/applicant' Component={Applicant}/>
              <Route path='policy' Component={Policy}/>
              <Route path='mobileapp' Component={MobileAppPage}/>
              <Route path='*' Component={ErrorPage}/>

            </Routes>
            <Footer/>
          </BrowserRouter>
        </DataContext.Provider>
        
      </ThemeProvider>
  );
}

export default App;


