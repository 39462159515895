import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";



export default function ContactForm (){

    const adminUrl = 'https://admin.wsp-india.com';

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        message: '',
    });
    
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        message: '',
    });
    
    const handleInputChange = (e) => {
        const {name, value } = e.target;
        // setFormData({ ...formData, [name]: value });

        let newValue = value;
        if (name === 'mobile') {
            // Limit input to 10 digits
            newValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-digits and limit to 10 digits
        }
        setFormData({
            ...formData,
            [name]: newValue
        });
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (formData.first_name.trim() === '') {
            newErrors.first_name = 'First Name is required';
            valid = false;
        }

        if (formData.last_name.trim() === '') {
            newErrors.last_name = 'Last Name is required';
            valid = false;
        }

        if (formData.email.trim() === '' || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            valid = false;
        }

        if (formData.mobile.trim() === '' || !/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Mobile number is required';
            valid = false;
        }
    
        if (formData.message.trim() === '') {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };


    const handleSumbit = (event) => {
       
        event.preventDefault();

        if(validateForm()){
            
            // Call the API to submit the form data here
            // You can use fetch, axios, or any other HTTP library to make the API request
    
            // Example using fetch:

            fetch(`${adminUrl}/api/contact-post`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(formData),
            })
            .then((response) => response.json())
            .then((data) => {
                Swal.fire('Success', 'Form submitted successfully!', 'success');
              
                setFormData({
                first_name: '',
                last_name: '',
                email: '',
                mobile: '',
                message: '',
                });
            })
            .catch((error) => {
                Swal.fire('Error', 'Form submission failed', error);
            })
            
        }
    }

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return(
        <Form onSubmit={handleSumbit}>
            <Row className="response-reverse">
                <Col sm={12} md={12} lg={6}>
                    <Form.Group className="mb-3" controlId="exampleFName.Controlinput1">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleInputChange} placeholder="First Name"/>
                        <div className="text-danger">{errors.first_name}</div>
                    </Form.Group>
                </Col>
                <Col sm={12} md={12} lg={6}>
                    <Form.Group className="mb-3" controlId="exampleLName.Controlinput1">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" name="last_name" value={formData.last_name} onChange={handleInputChange} placeholder="Last Name"/>
                        <div className="text-danger">{errors.last_name}</div>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="mb-3" controlId="exampleForm.Controlinput2">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="name@example.com"/>
                <div className="text-danger">{errors.email}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.Controlinput3">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control type="text" name="mobile" value={formData.mobile} onChange={handleInputChange} placeholder="10 digit mobile number"/>
                <div className="text-danger">{errors.mobile}</div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.Controlinput4">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" value={formData.message} onChange={handleInputChange} placeholder="Your message here..."/>
                <div className="text-danger">{errors.message}</div>
            </Form.Group>

            <div className="">
                <ReCAPTCHA sitekey="6LfcBCApAAAAAGBfGdxOUlHfOoMdZgc-wmJ0nysQ"
                    onChange={onChange}
                />
            </div>
            <Button type="submit" className="eva-btn eva-outline-white my-3 contact-btn">Send me quote</Button>
        </Form>
    );
}