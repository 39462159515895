import React from "react";
import { useContext } from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Searchbar from "./Searchbar";
import JobCard from "./JobCard";
import { DataContext } from "../../App";
import { Helmet } from "react-helmet";

export default function Career(){

    const ContextData = useContext(DataContext);
    
    console.log(ContextData); 

    return(
        <>
        <Helmet>
            <meta name="description" content="Western Safety Patrol provides customized security services with a focus on customers. Our team, including ex-police and military members, keeps you safe."/>
        </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('assets/image/dark-theme-background.jpg')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">Career</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/'} className="breadcrumb-item">Home</NavLink>
                            <NavLink to={'career'} className="breadcrumb-item active">Career</NavLink>
                        </div>
                    </Container>
                </div>                                                                         
            </div>

            <section className="career-section" >
                <Container>
                    <Searchbar content={ContextData}/>
                </Container>
            </section>

            <section className="py-5">
                <Container fluid>
                    <JobCard getData={ContextData}/>
                </Container>
            </section>
        </>
    )
}