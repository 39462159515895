
import { useState, useEffect } from "react";
import { Container, Row, Col, NavLink } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import './contact.css';
import ContactForm from "../../components/contact-form";
import { Helmet } from "react-helmet";


export default function ContactPage(){

    const [addressData, setAddressData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [apiData, setApiData] = useState(true);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
        try {
        // Fetch data from all APIs simultaneously
        const [addressResponse, contactResponse] = await Promise.all([
            fetch(`${adminUrl}/api/address`).then((response) => response.json()),
            fetch(`${adminUrl}/api/contact`).then((response) => response.json()),
        ]);
        
        setAddressData(addressResponse);
        setContactData(contactResponse);
        } catch (error) {
            console.error('Error fetching data:', error);
            setApiData(false);
        // Handle errors, set appropriate states or display error messages
        }finally{
            setLoader(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

   


    return(
        <>
            <Helmet>
                <meta name="description" content="Western Safety Patrol provides customized security services with a focus on customers. Our team, including ex-police and military members, keeps you safe."/>
            </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('assets/image/bg-image.png')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">Contact</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/'} className="breadcrumb-item">Home</NavLink>
                            <NavLink to={'contact'} className="breadcrumb-item active"> Contact</NavLink>
                        </div>
                    </Container>
                </div>
            </div>
            {
                loader ? 
                        <TailSpin
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass="spinloader"    
                            visible={true}
                        />
                
                 : 
            <section className="bg-section-contact">
                <Container fluid>
                    <Row>
                        <Col sm="12" md="12" lg="6">
                            <div className="contact-info">
                                <h2 className="title">Contact Us</h2>
                                <div className="contact-card">
                                    <div className="heading">
                                        <i className="fa-solid fa-phone"></i>
                                        <h4>Call Us</h4>

                                    </div>
                                        <div className="link-list">

                                            {
                                                apiData && contactData ? contactData.map((contact) => (
                                                    <>
                                                        <NavLink key={`contact-${contact.contact_title}`} to={`tel:${contact.contact_number}`}><strong>Ph</strong>{` – ${contact.contact_number} (${contact.contact_title})`}</NavLink><br/>
                                                    </>
                                                )
                                                ) : 
                                                (
                                                    <>
                                                        <NavLink key="contact-accounts" href="tel:9254029403"><strong>Ph</strong> – 92540-29403 (Accounts),</NavLink><br/>
                                                        <NavLink key="contact-sales" href="tel:9254029411"> 92540-29411 (Sales),</NavLink><br/>
                                                        <NavLink key="contact-helpline" href="tel:7056740202">70567-40202 (Helpline),</NavLink>
                                                    </>
                                                )
                                            }
                                        </div>
                                </div>
                                <div className="contact-card">
                                    <div className="heading">
                                        <i className="fa-solid fa-location-dot"></i>
                                        <h4>Location</h4>
                                    </div>
                                        <div className="link-list">
                                        {
                                            apiData && addressData ? 
                                            addressData.map((address) => (
                                                <NavLink key={`address-${address.id}`}><strong>{address.address_title}</strong> {`- ${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}</NavLink>
                                            )) :
                                            (
                                                <>
                                                    <NavLink><strong>Regd. Office</strong> – Tilak Nagar, 591 B, Shop No 1, Rohtak, Haryana, 124001</NavLink>
                                                    <NavLink><strong>Branch Office</strong> – Above PNB Bank, SCO 42, 1st Floor, SCO Market HSIIDC, Sector 53, Kundli Industrial Area, Sonipat, Haryana, 131028</NavLink>
                                                </>
                                            )
                                        }
                                        </div>
                                    
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="12" lg="6">
                            <div className="contact-form">
                                <ContactForm/> 
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            }

            <section>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3493.9076338508758!2d77.12504717542973!3d28.871365273195032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dabc44edca1e5%3A0x6e9da7afc7578b88!2sWestern%20Safety%20Patrol!5e0!3m2!1sen!2sin!4v1697451271243!5m2!1sen!2sin" width="100%" height="350" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}

                <iframe
                    title="Google Maps Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3493.9076338508758!2d77.12504717542973!3d28.871365273195032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dabc44edca1e5%3A0x6e9da7afc7578b88!2sWestern%20Safety%20Patrol!5e0!3m2!1sen!2sin!4v1697451271243!5m2!1sen!2sin"
                    width="100%"
                    height="350"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </section>
        </>
    );
}