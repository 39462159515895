import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import 'aos/dist/aos.css';
import ReactOwlCarousel from "react-owl-carousel";
import { Skeleton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Director(){

    const [teamData, setTeamData] = useState(null);
    const [apiData, setApiData] = useState(true);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
        try{
          const response = await fetch(`${adminUrl}/api/team`);
          const data = await response.json();
        
          setTeamData(data);
        }catch(error){
          console.error('error fetching data', error);
          setApiData(false);
        }finally{
            setLoader(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    // OwlCarousel Responsive //
    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };
    

    return (
        <section className="vision py-5" style={{backgroundColor: 'rgb(248, 249, 250)'}}>
            <Container fluid>
                    {
                        loader ? 
                        <Row className="align-items-center">
                            <Col sm="12" md="6" className="offset-md-4">
                                <h2 className="section-title">Director Vision</h2>
                            </Col>
                            <Col sm="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                <Skeleton variant="circular" width={150} height={150}>
                                    <Avatar/>
                                </Skeleton>
                            </Col>
                            <Col sm="12" md="4" lg="4" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                <Skeleton height={100}/>
                                <Skeleton width="40%"/>
                            </Col>
                        </Row> :
                        <ReactOwlCarousel className="owl-theme" responsive={responsiveOptions} autoplayHoverPause={true} margin={10} nav={false} dots={false} autoplayTimeout={2000} autoplay={true} loop={true}>
                                {
                                    apiData && teamData ? teamData.map((data, index) => (
                                        <Row key={`director-${index}`}>
                                            <Col lg={12}>
                                                <Row className="align-items-center">
                                                    <Col sm="12" md="12" className="offset-md-4">
                                                            <h2 className="section-title">{data.title}</h2>
                                                    </Col>
                                                    <Col sm="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                                        <div className="profile-md">
                                                            <img src={`${adminUrl}/uploads/profile/${data.director_photo}`} alt="mdprofile"/>
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" md="8" lg="8" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                                        <div className="content-text">
                                                            <p className="vision-content">{data.director_discription}</p>
                                                            <NavLink to={data.linkedin_link} target="_blank">
                                                                <p className="director-name">{`${data.director_name} - ${data.designation}`}</p>
                                                            </NavLink>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )) :
                                    (
                                        <Row>
                                            <Col sm="12" md="6" className="offset-md-4">
                                                <h2 className="section-title">Director Vision</h2>
                                            </Col>
                                            <Col sm="12" md="4" lg="4" className="d-flex justify-content-center" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                                <div className="profile-md">
                                                    <img src="assets/image/md-profile.jpg" alt="mdprofile"/>
                                                </div>
                                            </Col>
                                            <Col sm="12" md="8" lg="8" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
                                                <div className="content-text">
                                                    <p className="vision-content">
                                                        Western safety has consolidated it’s position as a leader in safety patrol and integrated surveillance solutions. Our standards ensure utmost safety for clients and their employees. WSP aims to expend globally, while seeking sustainable ways to engage with clients.
                                                    </p>
                                                    <p className="director-name">Sandeep Dahiya - Director</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                }
                        </ReactOwlCarousel>
                    }
            </Container>
        </section>
    )
}

