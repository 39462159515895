import { useState } from "react";
import Select from "react-select";




const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'none',
      color: '#000',
      borderRadius: '',
      border: 'none',
      boxShadow: 'none',

      '&:hover': {
        border: 'none',
        boxShadow: 'none',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#000',
      width: '100%',
    }),
  };




const DropDownButton = (props) =>{
  // const { options, placeName, value, handleInputChange, name } = props;
  
    return(
        <div style={{width: '100%'}}>
            <Select options={props.options} placeholder={props.placeName} value={props.value} onChange={(e) => props.handleInputChange(e)} styles={customStyles} name={props.name}/>
        </div>
    )
}

export default DropDownButton;