import { useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { NavLink, Link } from 'react-router-dom';
import styled from "styled-components";

const Height = styled.div`
    height: 71px;

    @media screen and (max-width: 1190px){
        height: 56px;
    }

`



 const Header = () => {

    const [profile, setProfile] = useState([]);
    const [apiDataAvailable, setApiDataAvailable] = useState(true);
    const [serviceData, setServiceData] = useState([]);
    const [whatsappNumber, setWhatsppNumber] = useState("9254029400"); // Replace this with your actual WhatsApp number

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
        try{
            // Fetch data from the API when the component mounts
            const [profileResponse, serviceDataReponse, whatsappResponse] = await Promise.all([
                fetch(`${adminUrl}/api/profile`).then((response) => response.json()),
                fetch(`${adminUrl}/api/service`).then((response) => response.json()),
                fetch(`${adminUrl}/api/whatsapp`).then((response) => response.json()),
            ]);
            setProfile(profileResponse);
            setServiceData(serviceDataReponse);
            setWhatsppNumber(whatsappResponse);

        }catch(error){
            console.error("Error fetching data", error);
            setApiDataAvailable(false);// API data is not available
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    

    const redirectToWhatsapp = () => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(whatsappNumber.whatsapp_number)}`;
        window.open(whatsappUrl, '_blank');
    };

    const handleNavSelect = () => {
        // This function will be called when a Nav.Link is selected
        // Close the Navbar toggle by setting the state to false
        document.getElementById("basic-navbar-nav").classList.remove("show");
        // console.log('remove show class');
    };

    return (                   
        <Height>
            <Navbar expand="lg" className="bg-body-tertiary fixed-top bg-dark navbar-light">
                <Container fluid>
                    <Navbar.Brand href="/">
                        {
                            apiDataAvailable && profile.company_logo ? (
                                <img src={`${adminUrl}/uploads/profile/${profile.company_logo}`} alt="logo" className="logo"/>
                            ) : 
                            (
                                <img src="assets/image/logo.png" alt="logo" className="logo"/>
                            )
                        }
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-white"><i className="fa-solid fa-bars"></i></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" onClick={handleNavSelect}> 
                        <Nav className="me-auto">
                            <NavLink to={'/'} className="nav-link">Home</NavLink>
                            <NavLink to={'/about'} className="nav-link">About us</NavLink>
                            {/* <Link to={'/'} className="nav-link hover-dropdown about-parent">About
                                <ul className="drop-list">
                                    <li>
                                        <NavLink to={'/about'} className="navlink about-child">About us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={'/contact'} className="navlink about-child">Contact us</NavLink>
                                    </li>
                                </ul>
                            </Link> */}
                            <NavLink to={'/service'} className="nav-link hover-dropdown service-parent">Service
                                <ul className="drop-list">
                                    {
                                        apiDataAvailable && serviceData.map((data) => (
                                            <li>
                                                <NavLink to={`serviceview/${data.slug}`} key={`service-list-${data.id}`} className="navlink service-child">{data.title}</NavLink>
                                            </li>
                                        )) 
                                    }
                                </ul>
                            </NavLink>
                            <NavLink to={'/career'} className="nav-link">Career</NavLink>
                            <NavLink to={'/contact'} className="nav-link">Contact us</NavLink>
                            {/* <Link className="nav-link" onClick={redirectToWhatsapp}><i className="fa-brands fa-whatsapp"></i></Link> */}
                            <NavLink to={'/mobileapp'} className="nav-link">Download</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Height>
    )
};

export default Header;
