import ReactOwlCarousel from "react-owl-carousel";
import { useState, useEffect } from "react";
import { Skeleton } from "@mui/material";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Testimonial(){
    

    const [testimonialData, setTestimonialData] = useState([]);
    const [apiData, setApiData] = useState(true);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
      try{
        const response = await fetch(`${adminUrl}/api/testimonial`);
        const data = await response.json();
      
        setTestimonialData(data);
      }catch(error){
        console.error('error fetching data', error);
        setApiData(false);
      }finally{
        setLoader(false);
      }
    }


    useEffect(() => {
      fetchData();
    },[]);

    const testimonial = [
        {
            id: 1,
            review: "I am pleased with the performance of the Western Safety Patrol staff assigned to our facilities.  The management responds quickly to questions and needs.  I would definitely recommend WSP to others.",
            src: "ellipse-4.svg",
            name: "Shiksha Sharma",
            designation: "manager",
            company: "Apollo Medicare"
        },
        {
            id: 2,
            review: "Things are going well. We genuinely appreciate the Western Safety Patrol partnership and your responsivess to the issues that arise at the shelter. If we extend to a different location, I will contact them for their service. Thanks again.",
            src: "amit_goyal.jpg",
            name: "Amit Goyal",
            designation: "Director",
            company: "Godhan Masale Foods pvt. ltd."
        },
        {
            id: 3,
            review: "Good service provided by Western Safety Patrol, quick repsonse, effective night patrolling. We wish all progress.",
            src: "kamal_batra.jpg",
            name: "Kamal Batra",
            designation: "Manager",
            company: "Natraj Stationary Products Pvt. Ltd."
        },
        {
            id: 4,
            review: "Good service provided by Western Safety Patrol, quick repsonse, effective night patrolling. We wish all progress.",
            src: "mohit-suri.jpg",
            name: "Kamal Batra",
            designation: "Manager",
            company: "Natraj Stationary Products Pvt. Ltd."
        },
    ];

    const responsiveOptions = {
        0: {
            items: 1, // Show 1 card at 0px and up
        },
        500: {
            items: 1, // Show 2 cards at 500px and up
        },
        1000: {
            items: 1, // Show 3 cards at 1000px and up
        },
    };

    return(
        <>
        {
            loader ? 
                <>
                <Skeleton width="100%" height={100} style={{backgroundColor: 'grey'}}/>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Skeleton variant="circular" className="text-center" width={100} height={100} style={{backgroundColor: 'grey'}}/>
                    <Skeleton width={200} height={30} style={{backgroundColor: 'grey'}}/>
                    <Skeleton width={250} height={50} style={{backgroundColor: 'grey'}}/>
                </div>
                </>
                
            :
            <ReactOwlCarousel className="owl-theme" margin={10} nav responsive={responsiveOptions} dots={false} autoPlay={true} autoplaySpeed={2000} loop={true}>
                {
                    apiData && testimonialData ? (
                        testimonialData.map((card) => (
                            <div key={`testimonial-${card.id}`} className="testi-box">
                                <p className="review">{ `${card.message}` }</p>
                                <div className="profile-img">
                                    <img src={`${adminUrl}/uploads/testimonial/${card.image}`} className="asd" alt="profileimage"/>
                                </div>
                                <div className="text-center">
                                    <p className="name">{card.name}</p>
                                    <p className="designation">{card.designation}-{card.company_name}</p>
                                </div>
                            </div>
                        ))
                    ): 
                    (
                        testimonial.map((card) => (
                            <div key={`testimonial-${card.id}`} className="testi-box">
                                <p className="review">{ `“${card.review}”` }</p>
                                <div className="profile-img">
                                    <img src={`./assets/image/${card.src}`} className="asd" alt="profileimage"/>
                                </div>
                                <div className="text-center">
                                    <p className="name">{card.name}</p>
                                    <p className="designation">{card.designation}-{card.company}</p>
                                </div>
                            </div>
                        ))

                    )
                }
            </ReactOwlCarousel>
        }
        </>
    );
}