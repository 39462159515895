
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { Col, Row } from 'react-bootstrap';



export default function TitlebarImageList() {


    const [galleryData, setGalleryData] = useState([]);
    const [loader, setLoader] = useState(true);

    const adminUrl = 'https://admin.wsp-india.com';

    const fetchData = async () => {
      try{
        const response = await fetch(`${adminUrl}/api/service`);
        const data = await response.json();
      
        setGalleryData(data);
      }catch(error){
        console.error('error fetching data', error);
      }finally{
        setLoader(false);
      }
    }


    useEffect(() => {
      fetchData();
    },[]);


    const itemData = [
      {
        img: 'rectangle-25.png',
        title: 'Security Officers',
      },
      {
        img: 'rectangle-26.png',
        title: 'Vehicle Patrol',
      },
      {
        img: 'rectangle-27.png',
        title: 'Alarm & Incident Response',
      },
      {
        img: 'event-security1.jpg',
        title: 'Event Security',
      },
      {
        img: 'security-consultant.jpg',
        title: 'Security Consultant',
      },
      {
        img: 'fire-safety.jpg',
        title: 'Fire Safety',
      }
    ];

  return (
    <React.Fragment>
      {
        loader ?  <Skeleton variant="circular">
           
          </Skeleton> :

          <Row>
            {
              galleryData ? (
                galleryData.map((data, index) => (
                  <Col md={6} lg={4}>
                    <NavLink key={`item-${index}`} to={`/serviceview/${data.slug}`}>
                      <div className='grid-container'>
                        <img src={`${adminUrl}/uploads/service/${data.image}`} className={`grid-item grid-item-${index}`} alt="" />
                        <p>{data.title}</p>
                      </div>
                    </NavLink>
                  </Col>
                  ))
              ) :
              (
                itemData.map((data, index) => (
                <div key={`item-${index}`}>
                  <img src={`assets/image/${data.img}`} className={`grid-item grid-item-${index}`} alt="" />
                  <p>{data.title}</p>
                </div>
                ))
              )
            }
          </Row>
      }
    </React.Fragment>
  );
}
