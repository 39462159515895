import React, { useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { DataContext } from "../../App";
import { useState, useContext } from "react";
import { Section } from "./CareerPage";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";


export default function Applicant(){
    
    const navigate = useNavigate();
    const ContextData = useContext(DataContext);
    const formRef = React.useRef(null);

    const adminUrl = 'https://admin.wsp-india.com';
    
    let getCareer = useLocation();
    // console.log("GET Career Info:",getCareer?.state);


    const [formData, setFormData] = useState({
        position_id: getCareer?.state.id,
        first_name: '',
        last_name: '',
        address: '',
        email: '',
        phone: '',
        experience: '',
        resume: null
    });
    const [errors, setErrors] = useState({});
        
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let newValue = value;
        if (name === 'phone') {
            // Limit input to 10 digits
            newValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-digits and limit to 10 digits
        }
        setFormData({
            ...formData,
            [name]: newValue
        });
        if (name in errors) {
            setErrors({
              ...errors,
              [name]: null
            });
        }
        
    };
        
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            resume: file
        });
    };
        
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const newErrors = {};
        
        if (!formData.position_id) {
            newErrors.position_id = "Position is required";
        }
      
        if (!formData.first_name) {
            newErrors.first_name = "First name is required";
        }
      
        if (!formData.last_name) {
            newErrors.last_name = "Last name is required";
        }
      
        if (!formData.address) {
            newErrors.address = "Address is required";
        }
      
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/^[^@]+@[^@]+\.[^@]+$/.test(formData.email)) {
            newErrors.email = "Email is invalid";
        }
      
        if (!formData.phone) {
            newErrors.phone = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = "Phone number must be 10 digits";
        }
      
        if (!formData.experience) {
            newErrors.experience = "Experience is required";
        }
      
        if (!formData.resume) {
            newErrors.resume = "Resume is required";
        } else if (formData.resume.size === 0) {
            newErrors.resume = "Please select a non-empty file";
        }
        
        setErrors(newErrors);
        
        if (Object.keys(newErrors).length === 0) {
            const formDataToSend = new FormData();
            formDataToSend.append('position_id', formData.position_id);
            formDataToSend.append('first_name', formData.first_name);
            formDataToSend.append('last_name', formData.last_name);
            formDataToSend.append('address', formData.address);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('experience', formData.experience);
            formDataToSend.append('resume', formData.resume);
            
            try {
                const response = await fetch(`${adminUrl}/api/applicant`, {
                    method: 'POST',
                    body: formDataToSend,
                    headers: {
                        // "Content-type": "multipart/form-data",
                        'Accept': 'application/json'
                    }
                });
        
                if (response.ok) {
                    Swal.fire('Success', 'Form submitted successfully!', 'success');
                     // Reset form after successful submission
                    setFormData({
                        position_id: getCareer?.state.id,
                        first_name: '',
                        last_name: '',
                        address: '',
                        email: '',
                        phone: '',
                        experience: '',
                        resume: null
                    });

                } else {
                    throw new Error('Form submission failed');
                }
            } catch (error) {
                Swal.fire('Error', error.message, 'error');
                console.log(error.response);
            }
        }
    };
        

    function onChange(value) {
        console.log("Captcha value:", value);
    }

    return (
        
        <React.Fragment>
            <Helmet>
                <meta name="description" content="Western Safety Patrol provides customized security services with a focus on customers. Our team, including ex-police and military members, keeps you safe."/>
            </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('/assets/image/dark-theme-background.jpg')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid>
                        <h1 className="page-name">Applicant</h1>
                        <div className="breadcrumb">
                            <Link className="breadcrumb-item" onClick={() => navigate(-1)}>career</Link>
                            {/* <NavLink to={`careerview/${getSlug.state}`}>Career Page</NavLink> */}
                            <p className="breadcrumb-item active">Applicant</p>
                        </div>
                    </Container>
                </div>
            </div>
            <Section>
                <Container fluid>
                    <Button variant="link" onClick={() => navigate(-1)}>Back to Job Desciption</Button>
                    <h3>Applicant Form</h3>
                    <Form className="my-3" onSubmit={handleSubmit} ref={formRef}>
                        <Row>
                            <Col lg="12">
                                <Form.Group className="mb-3" controlId="positionForm.ControlInput1">
                                    <Form.Label>Position</Form.Label>
                                    <Form.Select name="position_id" value={formData.position_id} onChange={handleInputChange}>
                                        <option>Select...</option>
                                        {
                                            ContextData ? ContextData.map((ele, index) => (
                                                <option key={index} value={ele.id}>{ele.title}</option>
                                            )) :
                                            <option value="1">Business Developer</option>
                                        }
                                    </Form.Select>
                                    <div className="text-danger">{errors.position_id}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3" controlId="firstNameForm.ControlInput2">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleInputChange}/>
                                    <div className="text-danger">{errors.first_name}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3" controlId="lastNameForm.ControlInput3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" name="last_name" value={formData.last_name} onChange={handleInputChange} />
                                    <div className="text-danger">{errors.last_name}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3" controlId="emailForm.ControlInput4">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="name@example.com" value={formData.email} onChange={handleInputChange}/>
                                    <div className="text-danger">{errors.email}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3" controlId="phoneForm.ControlInput5">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" name="phone" value={formData.phone} onChange={handleInputChange}/>
                                    <div className="text-danger">{errors.phone}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="12">
                                <Form.Group className="mb-3" controlId="addressForm.ControlInput6">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" name="address" value={formData.address} onChange={handleInputChange}/>
                                    <div className="text-danger">{errors.address}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3" controlId="experienceForm.ControlInput7">
                                    <Form.Label>Experience</Form.Label>
                                    <Form.Control type="text" name="experience" value={formData.experience} onChange={handleInputChange}/>
                                    <div className="text-danger">{errors.experience}</div>
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group className="mb-3" controlId="formFile">
                                    <Form.Label>Updated Resume <span className="text-danger">*</span> <span className="text-danger">(file size should be max 2 mb)</span></Form.Label>
                                    <Form.Control type="file"  name="resume" accept=".doc,.docx,application/msword,.pdf" onChange={handleFileChange} />
                                    <span className="text-success">accept only .pdf or .doc/.docx format</span>
                                    <div className="text-danger">{errors.resume}</div>
                                </Form.Group>
                            </Col>
                            <div className="mb-3">
                                <ReCAPTCHA sitekey="6LfcBCApAAAAAGBfGdxOUlHfOoMdZgc-wmJ0nysQ" onChange={onChange}/>
                            </div>
                            <Col md="12">
                                <Button type="submit" variant="contained">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Section>
        </React.Fragment>
    )
}