import './service.css';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import TitlebarImageList from '../../components/TitlebarImageList';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Paragraph = styled.p`
    text-align: justify;
`

export default function Service(){

    

    return (
        <>
        <Helmet>
            <meta name="description" content="Our professional team offers reliable safety patrols for Western regions, ensuring secure premises and timely responses."/>
        </Helmet>
            <div className="bg-image" style={{backgroundImage: "URL('assets/image/banner-img2.jpg')"}}>
                <div className="py-3 position-relative" style={{zIndex: "999"}}>
                    <Container fluid> 
                        <h1 className="page-name">Service</h1>
                        <div className="breadcrumb">
                            <NavLink to={'/'} className="breadcrumb-item">Home</NavLink>
                            <NavLink to={'about'} className="breadcrumb-item active"> Service</NavLink>
                        </div>
                        
                    </Container>
                </div>
            </div>

            <section className="py-5">
                <Container fluid>
                    <Row>
                        <Col sm="12" md="4" lg="4">
                            <div className="service-rectangle-wrapper">
                                <div className="frame">
                                    <div className="frame-icon">
                                        <i className="fa-solid fa-user-group"></i>
                                    </div>
                                    <h4 className="text-center">On-site Guarding</h4>
                                    <Paragraph>
                                        Our manned Guarding Service provides customers with fully-trained, professional Security Guard services. Whether your requirements are full-time, temporary or for special events, our officers are constantly evaluated and monitored to ensure we provide industry-leading service levels
                                    </Paragraph>
                                </div>  
                            </div>
                        </Col>
                        <Col sm="12" md="4" lg="4">
                            <div className="service-rectangle-wrapper">
                                <div className="frame">
                                    <div className="frame-icon">
                                        <i className="fa-solid fa-car"></i>
                                    </div>
                                    <h4 className="text-center">Mobile Guarding</h4>
                                    <Paragraph>
                                        Our range of cost-effective Mobile Security response services can operate individually or in combination. From uniformed vehicle patrols, alarm response and lone worker escorts to protect your business continuity.
                                    </Paragraph>
                                </div>  
                            </div>      
                        </Col>
                        <Col sm="12" md="4" lg="4">
                            <div className="service-rectangle-wrapper">
                                <div className="frame">
                                    <div className="frame-icon">
                                        <i className="fa-solid fa-code-branch"></i>
                                    </div>
                                    <h4 className="text-center">Remote Guarding</h4>
                                    <Paragraph>
                                        We have the experience, skills, and advanced technology to watch for and stop incidents before they happen. Our clients can experience true peace of mind knowing their property is safe, secure, and protected with state-of-the-art remote guarding services.
                                    </Paragraph>
                                </div>  
                            </div>
                        </Col>
                    </Row>
                   
                </Container>
            </section>
            
            <section className="py-5">
                <Container fluid>
                    <TitlebarImageList/>
                </Container>
            </section>
        </>
    );
}